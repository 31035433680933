import React from "react"

import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import * as classes from "./corporate_management_training.module.scss"
import loadable from "@loadable/component"
const CorporateBanner = loadable(() => import("./banner"), {
  fallback: <div></div>,
})
export default function CorporateManagement() {
  return (
    <React.Fragment>
      <MainHeader />
      <CorporateBanner />
      <div className={classes.text_div}>
        <h2>Corporate Management and Training</h2>
        <p>
          Master Trainers provides the greatest level of excellence, by
          arranging for corporate trainers to make sure you get the right person
          for the right job. With this Corporate Management and Training
          service, we make sure that all details are simple, seamless, and
          handled efficiently. A streamlined organizational process is only so
          effective without training, and that’s where we step in to break the
          barrier. Whenever you work with Master Trainers Pvt Ltd, you can know
          that your corporate affairs have been entrusted to only the most
          experienced and capable individuals.
        </p>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
